import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import "firebase/compat/database";
var firebaseConfig = {
  apiKey: "AIzaSyALTSDZwQEuCRgzNtAneWgdIApHZH61imc",
  databaseURL:"https://projetvisio-c4032-default-rtdb.firebaseio.com/"
};firebase.initializeApp(firebaseConfig);

export const db = firebase;

var firepadDB = firebase.database().ref();
const auth = getAuth();
const urlparams = new URLSearchParams(window.location.search);

// Generate or retrieve the roomId from the URL
let roomId = urlparams.get("roomId");

if (!roomId) {
  // If roomId does not exist in the URL, create a new one (new meeting)
  const newRoomRef = firepadDB.push();
  roomId = newRoomRef.key; // Get the generated unique roomId
  window.history.replaceState(null, "New Room", "?roomId=" + roomId); // Update the URL with the new roomId
} else {
  // If roomId exists, join the existing room
  firepadDB = firepadDB.child(roomId);
}

export { auth, firepadDB, roomId };
