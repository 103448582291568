import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faVideo,
  faDesktop,
  faPhoneSlash,
  faVideoSlash,
  faMicrophoneSlash,
  
} from "@fortawesome/free-solid-svg-icons";
import "./MeetingFooter.css";
import { Button, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const MeetingFooter = (props) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  const location =  useLocation();
  const { mode } = props;
  const [streamState, setStreamState] = useState({
    mic: true,
    video: false,
    screen: false,
  });
  const [showModal, setShowModal] = useState("");
  const roomId = location.pathname.split("/")[2]; // Extraire le roomId de l'URL

  const micClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        mic: !currentState.mic,
      };
    });
  };

  const onVideoClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        video: !currentState.video,
      };
    });
  };
 /*  const onScreenClick = () => {
    props.onScreenClick(setScreenState);
  }; */

  const setScreenState = (isEnabled) => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        screen: isEnabled,
      };
    });
  };
  const onLeaveClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleLeaveConfirm = () => {
    console.log("Quitter la réunion");
    window.location.href = "/Dashbord"; 
  };
  const copyRoomIdToClipboard = () => {
    navigator.clipboard.writeText(roomId).then(() => {
      Toast.fire({
        icon: "success",
        title: "ID réunion copié dans le presse-papier !",
      });
    }).catch((err) => {
      console.error("Erreur lors de la copie de l'ID : ", err);
    });
  };
  useEffect(() => {
    props.onMicClick(streamState.mic);
  }, [streamState.mic]);

  useEffect(() => {
    props.onVideoClick(streamState.video);
  }, [streamState.video]);
 
  return (
    <div className="meeting-footer">
        <button onClick={copyRoomIdToClipboard} class="button-86" role="button">Copier l'ID de la réunion</button>

      {mode === "audio" && (
        <div
          className={"meeting-icons " + (!streamState.mic ? "active2" : "")}
          onClick={micClick}
        >
          <FontAwesomeIcon
            icon={!streamState.mic ? faMicrophoneSlash : faMicrophone}
            title="Audio"
          />
        </div>
      )}

      {mode === "video" && (
        <>
        <div
          className={"meeting-icons " + (!streamState.mic ? "active2" : "")}
          onClick={micClick}
        >
          <FontAwesomeIcon
            icon={!streamState.mic ? faMicrophoneSlash : faMicrophone}
            title="Audio"
          />
        </div>
          <div
            className={"meeting-icons " + (!streamState.video ? "active2" : "")}
            onClick={onVideoClick}
          >
            <FontAwesomeIcon
              icon={!streamState.video ? faVideoSlash : faVideo}
              title="Vidéo"
            />
          </div>
          {/* <div
            className="meeting-icons"
            onClick={onScreenClick}
            disabled={streamState.screen}
          >
            <FontAwesomeIcon icon={faDesktop} />
          </div> */}
        </>
      )}

      <div className="meeting-icons bg-danger" onClick={onLeaveClick}>
        <FontAwesomeIcon icon={faPhoneSlash} />
      </div>
     


      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Êtes-vous sûr de vouloir quitter la réunion ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            style={{ backgroundColor: "#264a67", border: "none" }}
            onClick={handleLeaveConfirm}
          >
            Quitter
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MeetingFooter;
