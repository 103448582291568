import React, { useEffect, useRef } from "react";
import "./Participants.css";
import { connect } from "react-redux";
import { Participant } from "./Participant/Participant";
import { useLocation } from "react-router-dom";

const Participants = (props) => {
  const videoRef = useRef(null);
  const location = useLocation();
  let participantKey = Object.keys(props.participants);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = props.stream;
      videoRef.current.muted = true;
    }
  }, [props.currentUser, props.stream]);

  const currentUser = props.currentUser
    ? Object.values(props.currentUser)[0]
    : null;

  // Récupérer la taille de la grille en fonction du nombre de participants
  let gridCol = participantKey.length === 1 ? 1 : participantKey.length <= 4 ? 2 : 4;
  const gridColSize = participantKey.length <= 4 ? 1 : 2;
  let gridRowSize = participantKey.length <= 4
    ? participantKey.length
    : Math.ceil(participantKey.length / 2);

  const screenPresenter = participantKey.find((element) => {
    const currentParticipant = props.participants[element];
    return currentParticipant.screen;
  });

  if (screenPresenter) {
    gridCol = 1;
    gridRowSize = 2;
  }

  const participants = participantKey.map((element, index) => {
    const currentParticipant = props.participants[element];
    if (currentParticipant.currentUser) {
      return null;
    }
    const pc = currentParticipant.peerConnection;
    const remoteStream = new MediaStream();

    if (pc) {
      pc.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });
        const videoElement = document.getElementById(
          `participantVideo${index}`
        );
        if (videoElement) videoElement.srcObject = remoteStream;
      };
    }
// Affichage de l'état du micro (activé/désactivé)
const micStatusIcon = currentParticipant.audio ? "🔊" : "🔇"; // Icône micro
    return (
      <div className="participant" key={index}>
        <Participant
        key={index}
        currentParticipant={currentParticipant}
        curentIndex={index}
        hideVideo={screenPresenter && screenPresenter !== element}
        showAvatar={
          !currentParticipant.video && !currentParticipant.screen
        }
        micEnabled={currentParticipant.audio}
      />
      <div className="audio-status">
          {micStatusIcon}
        </div>
      </div>
      
    );
  });
  return (
    <div
      style={{
        "--grid-size": gridCol,
        "--grid-col-size": gridColSize,
        "--grid-row-size": gridRowSize,
      }}
      className={`participants`}
    >
      {participants}
      <Participant
        participant={currentUser}
        currentParticipant={currentUser}
        curentIndex={participantKey.length}
        hideVideo={screenPresenter && !currentUser.screen}
        videoRef={videoRef}
        showAvatar={currentUser && !currentUser.video && !currentUser.screen}
        currentUser={true}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    participants: state.participants,
    currentUser: state.currentUser,
    stream: state.mainStream,
  };
};

export default connect(mapStateToProps)(Participants);
