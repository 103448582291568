import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashbord.css";
import { Modal, Form, Button } from "react-bootstrap";
import { signOut } from "firebase/auth";
import { auth } from "../../db/firebase";
import { getDatabase, ref, onValue, push, set } from "firebase/database";
import Swal from "sweetalert2";

function Dashboard(props) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  const database = getDatabase();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [meetingMode, setMeetingMode] = useState("video"); // Par défaut vidéo
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);
  const [userId, setUserId] = useState(localStorage.getItem("userID"));

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid);
      localStorage.setItem("userID", user.uid);
    }
  }, []);

  // Fonction pour créer une nouvelle réunion avec roomId unique
  const createMeeting = (meetingName) => {
    const database = getDatabase(); // Obtenir l'instance de la base de données
    const newRoomRef = push(ref(database, "meetings")); // Créer une référence avec push()
  const roomId = newRoomRef.key; // Récupérer le roomId
    // Utilisez set() sur la référence obtenue avec push()
    set(newRoomRef, {
      meetingName: meetingName,
      mode: meetingMode,
      createdAt: Date.now(),

    })
      .then(() => {
        Toast.fire({
          icon: "success",
          title: "Réunion créée avec succès",
        });
        // Rediriger vers la salle de réunion avec le roomId unique
        
        navigate(`/Salle/${roomId}`, { state: { mode: meetingMode } });
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: "Erreur lors de la création de la réunion",
        });
      });
  };
  
  // Fonction pour rejoindre une réunion en utilisant le nom de la réunion
  const joinMeetingById = (meetingId) => {
    const meetingRef = ref(database, `meetings/${meetingId}`);
    
    onValue(meetingRef, (snapshot) => {
      const meeting = snapshot.val();
      
      if (meeting) {
        const meetingMode = meeting.mode || "video"; // Récupérer le mode de la réunion
        // Rediriger vers la salle de réunion avec le roomId correspondant
        navigate(`/Salle/${meetingId}`, { state: { mode: meetingMode } });
      } else {
        Toast.fire({
          icon: "error",
          title: "Aucune réunion trouvée avec cet ID.",
        });
      }
    });
  };
  

  const handleSubmitCreateMeeting = (e) => {
    e.preventDefault();
    const meetingName = e.target.elements.meetingName.value;
    createMeeting(meetingName); 
    setShow(false); 
  };

  const handleSubmitJoinMeeting = (e) => {
    e.preventDefault();
    const meetingId = e.target.elements.meetingId.value;
    joinMeetingById(meetingId); 
    setShow2(false); 
  };
  

  const Deconnect = () => {
    signOut(auth)
      .then(() => {
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleModeChange = (e) => {
    setMeetingMode(e.target.value); // Changer le mode entre audio et vidéo
  };

  return (
    <div className="dashboard">
      <header id="header" className="header d-flex align-items-center fixed-top">
        <div className="container-fluid container-xl position-relative d-flex align-items-center">
          <a href="/" className="logo d-flex align-items-center me-auto">
            <img src="images/logo_dam.png" alt="" />
          </a>
          <a className="btn-getstarted" href="" onClick={Deconnect}>
            Déconnexion
          </a>
        </div>
      </header>
      <section id="hero" className="hero section dark-background">
        <div className="container">
          <div className="row gy-4">
            <div data-aos="zoom-out">
              <p>
              Créez facilement une réunion en
                quelques clics, en invitant vos collègues et partenaires à se
                joindre à vous instantanément. Si vous avez déjà un nom de
                réunion, entrez-le simplement pour participer et commencer à
                collaborer. Profitez d'une interface élégante et efficace,
                conçue pour maximiser votre productivité et faciliter vos
                échanges. Laissez la technologie travailler pour vous et
                concentrez-vous sur ce qui compte vraiment : vos projets et vos
                collaborations.
              </p>
              <div className="d-flex mt-7 justify-content-center">
                <button
                  type="button"
                  onClick={handleShow}
                  className="btn-get-started"
                >
                  Créer une réunion
                </button>
                <button
                  type="button"
                  onClick={handleShow2}
                  className="glightbox btn-watch-video d-flex align-items-center"
                >
                  <span>Rejoindre une réunion</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal pour créer une réunion */}
      <Modal show={show} onHide={handleClose} {...props} centered>
        <Modal.Header closeButton>
          <Modal.Title>Création d'une réunion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitCreateMeeting}>
            <div className="input-field2">
              <input
                type="text"
                name="meetingName"
                placeholder="Saisissez le nom de votre réunion"
                required
              />
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="meetingMode"
                  value="audio"
                  checked={meetingMode === "audio"}
                  onChange={handleModeChange}
                />
                Réunion audio
              </label>
              <label style={{ marginLeft: "20px" }}>
                <input
                  type="radio"
                  name="meetingMode"
                  value="video"
                  checked={meetingMode === "video"}
                  onChange={handleModeChange}
                />
                Réunion vidéo
              </label>
            </div>
            <Button
              type="submit"
              style={{
                backgroundColor: "#264a67",
                border: "none",
                marginTop: "10px",
                marginLeft: "80%",
              }}
            >
              Valider
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal pour rejoindre une réunion */}
      <Modal show={show2} onHide={handleClose2} {...props} centered>
        <Modal.Header closeButton>
          <Modal.Title>Rejoindre une réunion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitJoinMeeting}>
            <div className="input-field2">
              <input
                type="text"
                name="meetingId"
                placeholder="Saisissez l'ID de la réunion"
                required
              />
            </div>
            <Button
              type="submit"
              style={{
                backgroundColor: "#264a67",
                border: "none",
                marginTop: "10px",
                marginLeft: "80%",
              }}
            >
              Valider
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Dashboard;
