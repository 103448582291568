import React, { useRef, useEffect } from "react";
import MeetingFooter from "../MeetingFooter/MeetingFooter";
import Participants from "../Participants/Participants";
import "./MainScreen.css";
import { connect } from "react-redux";
import { setMainStream, updateUser } from "../store/actioncreator";
import { useLocation } from "react-router-dom";

const MainScreen = (props) => {
  const location = useLocation();
  const { mode } = location.state; 
  const participantRef = useRef(props.participants);

  const onMicClick = (micEnabled) => {
    if (props.stream) {
      props.stream.getAudioTracks()[0].enabled = micEnabled;
      props.updateUser({ audio: micEnabled });

      if (micEnabled) {
        const audioContext = new AudioContext();
        const source = audioContext.createMediaStreamSource(props.stream);

        // Création du filtre passe-bas
        const lowpassFilter = audioContext.createBiquadFilter();
        lowpassFilter.type = "lowpass";
        lowpassFilter.frequency.value = 15000;

      
        const noiseReducer = audioContext.createDynamicsCompressor();
        noiseReducer.threshold.setValueAtTime(-50, audioContext.currentTime);
        noiseReducer.knee.setValueAtTime(40, audioContext.currentTime);
        noiseReducer.ratio.setValueAtTime(20, audioContext.currentTime);
        noiseReducer.attack.setValueAtTime(0, audioContext.currentTime);
        noiseReducer.release.setValueAtTime(0.25, audioContext.currentTime);

        // Chaînage des filtres
        source.connect(lowpassFilter);
        lowpassFilter.connect(noiseReducer);
        noiseReducer.connect(audioContext.destination);
      }
    }
  };

  const onVideoClick = (videoEnabled) => {
    if (props.stream) {
      props.stream.getVideoTracks()[0].enabled = videoEnabled;
      props.updateUser({ video: videoEnabled });
    }
  };

  useEffect(() => {
    participantRef.current = props.participants;
  }, [props.participants]);

  

  const leaveMeeting = () => {
    if (props.stream) {
      props.stream.getTracks().forEach((track) => track.stop());
    }
    participantRef.current.forEach((participant) => {
      if (participant.peerConnection) {
        participant.peerConnection.close();
      }
    });
    window.location.href = "/Dashbord";
  };

  return (
    <div className="wrapper">
      <div className="main-screen">
        <Participants />

      </div>

      <div className="footer">
        <MeetingFooter
          onMicClick={onMicClick}
          onVideoClick={onVideoClick}
          onLeaveClick={leaveMeeting}
          mode={mode}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    participants: state.participants,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    updateUser: (user) => dispatch(updateUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);