import React, { useEffect } from "react";
import MainScreen from "../MainScreen/MainScreen";
import { db, auth, firepadDB } from "../../db/firebase";
import "../../App.css";
import {
  setMainStream,
  addParticipant,
  setUser,
  removeParticipant,
  updateParticipant,
} from "../store/actioncreator";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

function Salle(props) {
  const user = auth.currentUser;
  const location = useLocation();
  const userName = user ? user.displayName : "";
  const roomId = location.pathname.split("/")[2]; // Extraire le roomId de l'URL

  const getUserStream = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    // Désactiver la piste audio entrante pour l'utilisateur lui-même
    const audioTrack = localStream.getAudioTracks()[0];
    audioTrack.enabled = true; // Assurez-vous que l'audio est envoyé
    const videoTrack = localStream.getVideoTracks()[0];
    videoTrack.enabled = true; // Assurez-vous que la vidéo est envoyée

    // Masquer la vidéo par défaut
    videoTrack.enabled = false; // Assurez-vous que la vidéo est masquée
    // Créer une nouvelle piste audio avec le gain mis à zéro pour l'utilisateur
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(localStream);
    const gainNode = audioContext.createGain();
    gainNode.gain.value = 0; // Mettre le gain à 0 pour couper l'audio entrant pour l'utilisateur
    source.connect(gainNode);
    gainNode.connect(audioContext.destination);

    return localStream;
  };

  useEffect(() => {
    const setupStream = async () => {
      const stream = await getUserStream();
      props.setMainStream(stream);
      connectedRef.on("value", (snap) => {
        if (snap.val()) {
          const defaultPreference = {
            audio: true,
            video: false,
          };
          const userStatusRef = participantRef.push({
            userName,
            preferences: defaultPreference,
          });
          props.setUser({
            [userStatusRef.key]: { name: userName, ...defaultPreference },
          });
          userStatusRef.onDisconnect().remove();
        }
      });
    };

    setupStream();
  }, [props.setMainStream, props.setUser, userName]);
  const connectedRef = db.database().ref(".info/connected");
  // Référence Firebase en fonction du roomId
  const participantRef = firepadDB.child(`rooms/${roomId}/participants`);

  useEffect(() => {
    if (props.stream && props.user) {
      participantRef.on("child_added", (snap) => {
        const preferenceUpdateEvent = participantRef
          .child(snap.key)
          .child("preferences");
        preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
          props.updateParticipant({
            [snap.key]: {
              [preferenceSnap.key]: preferenceSnap.val(),
            },
          });
        });
        const { userName: name, preferences = {} } = snap.val();
        props.addParticipant({
          [snap.key]: {
            name,
            ...preferences,
          },
        });
      });
      participantRef.on("child_removed", (snap) => {
        props.removeParticipant(snap.key);
      });
    }
  }, [props.user, props.stream]);

  return (
    <div className="salon">
      <MainScreen />
    </div>
  );
}

const mapStateToProps = (state) => ({
  stream: state.mainStream,
  user: state.currentUser,
});

const mapDispatchToProps = {
  setMainStream,
  addParticipant,
  setUser,
  removeParticipant,
  updateParticipant,
};

export default connect(mapStateToProps, mapDispatchToProps)(Salle);
