import { firepadDB,roomId } from "./firebase";
import { store } from "../index";

const participantRef = firepadDB.child(`rooms/${roomId}/participants`);

export const updatePreference = (userId, preference) => {
  const currentParticipantRef = participantRef.child(userId).child("preferences");
  setTimeout(() => {
    currentParticipantRef.update(preference);
  });
};

export const createOffer = async (peerConnection, receiverId, createdID, roomId) => {
  const currentParticipantRef = firepadDB.child(`rooms/${roomId}/participants`).child(receiverId);
  peerConnection.onicecandidate = (event) => {
    if (event.candidate) {
      currentParticipantRef.child("offerCandidates").push({
        ...event.candidate.toJSON(),
        userId: createdID,
      });
    }
  };

  const offerDescription = await peerConnection.createOffer();
  await peerConnection.setLocalDescription(offerDescription);

  const offer = {
    sdp: offerDescription.sdp,
    type: offerDescription.type,
    userId: createdID,
  };

  await currentParticipantRef.child("offers").push().set({ offer });
};

export const initializeListensers = async (userId, roomId) => {
  const participantRef = firepadDB.child(`rooms/${roomId}/participants`);
  const currentUserRef = participantRef.child(userId);

  currentUserRef.child("offers").on("child_added", async (snapshot) => {
    const data = snapshot.val();
    if (data?.offer) {
      const pc =  store.getState().participants[data.offer.userId].peerConnection;
      await pc.setRemoteDescription(new RTCSessionDescription(data.offer));
      await createAnswer(data.offer.userId, userId);

      // Ajoutez la piste audio et vidéo du flux principal
      const mainStream = store.getState().mainStream;
      mainStream.getTracks().forEach((track) => {
        pc.addTrack(track, mainStream);
      });

      // Créez une réponse
      await createAnswer(data.offer.userId, userId, pc, roomId);
    }
  });

  currentUserRef.child("offerCandidates").on("child_added", (snapshot) => {
    const data = snapshot.val();
    if (data.userId) {
      const pc = store.getState().participants[data.userId].peerConnection;
      pc.addIceCandidate(new RTCIceCandidate(data));
    }
  });

  currentUserRef.child("answers").on("child_added", (snapshot) => {
    const data = snapshot.val();
    if (data?.answer) {
      const pc = store.getState().participants[data.answer.userId].peerConnection;
      pc.setRemoteDescription(new RTCSessionDescription(data.answer));
    }
  });

  currentUserRef.child("answerCandidates").on("child_added", (snapshot) => {
    const data = snapshot.val();
    if (data.userId) {
      const pc = store.getState().participants[data.userId].peerConnection;
      pc.addIceCandidate(new RTCIceCandidate(data));
    }
  });
};

const createAnswer = async (otherUserId, userId, roomId) => {
  const pc = store.getState().participants[otherUserId]?.peerConnection;

  // Vérifiez si pc est défini
  if (!pc) {
    console.error("Erreur : Le RTCPeerConnection n'est pas défini pour l'utilisateur " + otherUserId);
    return;
  }

  const participantRef1 = firepadDB.child(`rooms/${roomId}/participants`).child(otherUserId);
    // Définir l'événement onicecandidate uniquement si pc est défini
  pc.onicecandidate = (event) => {
    if (event.candidate) {
      participantRef1.child("answerCandidates").push({
        ...event.candidate.toJSON(),
        userId: userId,
      });
    }
  };

  const answerDescription = await pc.createAnswer();
  await pc.setLocalDescription(answerDescription);

  const answer = {
    type: answerDescription.type,
    sdp: answerDescription.sdp,
    userId: userId,
  };

  await participantRef1.child("answers").push().set({ answer });
};
