import React, { useState } from "react";
import "./SignInSignUp.css";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../db/firebase";
import Swal from "sweetalert2";

export const SignInSignUp = () => {
  const [isSignUpActive, setIsSignUpActive] = useState(false);

  const toggleSignUp = () => {
    setIsSignUpActive(!isSignUpActive);
  };

  const switchToSignIn = () => {
    setIsSignUpActive(false);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  const user = auth.currentUser;
  let userName = "";
  if (user !== null) {
    userName = user.displayName;
  }

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [submitButton2Disabled, setSubmitButton2Disabled] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    if (!name || !email || !password) {
      Toast.fire({
        icon: "error",
        title: "Tous les champs sont requis"
      });
      return;
    }

    setSubmitButtonDisabled(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        setSubmitButtonDisabled(false);
        const user = res.user;
        await updateProfile(user, {
          displayName: name,
        });
        Toast.fire({
          icon: "success",
          title: "Inscription réussie"
        });
        switchToSignIn();
      })
      .catch((err) => {
        setSubmitButtonDisabled(false);
        Toast.fire({
          icon: "error",
          title: err + "Inscription échouée"
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      Toast.fire({
        icon: "error",
        title: "Tous les champs sont requis"
      });
      return;
    }

    setSubmitButton2Disabled(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        setSubmitButton2Disabled(false);
        const userToken = await res.user.getIdToken();
        localStorage.setItem('userToken', userToken);
        localStorage.setItem('userName', res.user.displayName);
        Toast.fire({
          icon: "success",
          title: "Connexion réussie"
        });
        navigate("/Dashbord");
      })
      .catch((err) => {
        setSubmitButton2Disabled(false);
        Toast.fire({
          icon: "error",
          title: "Email ou mot de passe incorrect"
        });
      });
  };

  return (
    <div className={`contain ${isSignUpActive ? "sign-up-mode" : ""}`}>
      <div className="forms-contain">
        <div className="signin-signup">
          <form action="#" className="sign-in-form">
            <h2 className="title">Connexion</h2>
            <div className="input-field">
              <input type="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="input-field">
              <input type="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </div>
            <input type="submit" value="Se connecter" className="btn solid" disabled={submitButton2Disabled} onClick={handleSubmit} />
          </form>
          <form action="#" className="sign-up-form">
            <h2 className="title">Inscription</h2>
            <div className="input-field">
              <input type="text" value={name} placeholder="Username" onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="input-field">
              <input type="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="input-field">
              <input type="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </div>
            <input type="submit" className="btn" value="S'inscrire" onClick={submit} disabled={submitButtonDisabled} />
          </form>
        </div>
      </div>
      <div className="panels-contain">
        <div className="panel left-panel">
          <div className="content">
            <h3>Nouveau ici ?</h3>
            <p>
              Si vous n'avez pas encore de compte, inscrivez-vous dès maintenant
              pour découvrir tout ce que nous avons à offrir.
            </p>
            <button
              className="btn transparent"
              id="sign-up-btn"
              onClick={toggleSignUp}
            >
              S'inscrire
            </button>
          </div>
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>Un de nous ?</h3>
            <p>
              Veuillez vous connecter pour accéder à votre compte et profiter de
              toutes nos fonctionnalités.
            </p>
            <button
              className="btn transparent"
              id="sign-in-btn"
              onClick={toggleSignUp}
            >
              Se connecter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInSignUp;
