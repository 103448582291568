import Accueil from "./Accueil";
import SignInSignUp from "./components/Authentification/Inscription";
import Dashboard from "./components/Dashbord/Dashbord";
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/Context';
import Salle from './components/Salle/Salle';
import ProtectedRoute from './components/CheckAuth';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/authentification" element={<SignInSignUp />} />
          <Route path="/Dashbord" element={
            <ProtectedRoute>
              <Dashboard/>
            </ProtectedRoute>
          } />
           <Route path="/Salle/:meetingId" element={<Salle />} />   
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
