import "./theme.css";
import { useNavigate } from "react-router-dom";
export const Accueil = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/authentification");
  };
  return (
    <div className="App">
      <div className="header_section">
        <div className="container">
          <nav className="navbar navbar-dark ">
            <a className="logo" href="index.html">
              <img src="images/logo_dam.png" alt="Logo" />
            </a>
          </nav>
        </div>
        <div className="banner_section layout_padding">
          <div id="my_slider" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="taital_main">
                        <h4 className="banner_taital">Kapital Visio</h4>
                        <p className="banner_text">
                          Maintenez le contact, échangez et travaillez ensemble.
                          Grâce à notre plateforme,élargissez vos horizons
                          au-delà des limites géographiques. C'est la clé du
                          succès.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div data-aos="zoom-in" data-aos-delay="200">
                        <img
                          src="images/Group video-amico-fotor-20240731155237.png"
                          className="image_1"
                          alt="Banner"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Add more carousel items here */}
            </div>
          </div>
        </div>
        <section className="countdown-section">
          <div className="countdown-img">
            <img src="images/Dam1.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6">
                <div className="section-title1">
                  <p>
                    Il est aussi simple d'inviter des personnes que d'envoyer un
                    lien, et tout le monde peut rejoindre la réunion avant même
                    de s'inscrire. Que vous utilisiez un ordinateur portable ou
                    un smartphone, vous êtes prêt à participer. Notre plateforme
                    de visioconférence est conçue pour être accessible à tous,
                    où que vous soyez et quel que soit votre appareil. Profitez
                    d'une expérience utilisateur sans tracas, où la simplicité
                    et l'efficacité sont au cœur de chaque interaction.
                    Connectez-vous instantanément et commencez à collaborer en
                    un clic!
                    <div className="read_bt ">
                      <a onClick={handleRedirect}>Commencer</a>
                    </div>
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="container mb-8 mb-lg-11">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-7">
            <h3 className="fs-4 fs-lg-3 fw-bold text-center mb-2 mb-lg-x1">
              Comment ça <span className="text-nowrap">marche?</span>
            </h3>
          </div>
          <div className="col-12">
            <div className="row g-sm-2 g-lg-3 align-items-center timeline">
              <div className="col-12 col-lg-4 d-flex flex-row flex-lg-column justify-content-center gap-2 gap-sm-x1 gap-md-4 gap-lg-0">
                <div className="timeline-step-1 w-25 w-lg-100 mb-4 mb-lg-5 mb-xl-6">
                  <div className="timeline-item d-flex justify-content-center">
                    <div className="timeline-icon bg-primary rounded-circle d-flex justify-content-center align-items-center">
                      <span className="fs-6 fs-lg-5 fs-xl-4 text-white">1</span>
                    </div>
                  </div>
                </div>
                <div className="py-1 py-lg-0 px-lg-5 w-75 w-sm-50 w-lg-100 timeline-content">
                  <h6 className="fs-8 fw-bold text-lg-center lh-lg mb-2">
                    Lancer la plateforme
                  </h6>
                </div>
              </div>
              <div className="col-12 col-lg-4 d-flex flex-row flex-lg-column justify-content-center gap-2 gap-sm-x1 gap-md-4 gap-lg-0">
                <div className="timeline-step-2 w-25 w-lg-100 mb-4 mb-lg-5 mb-xl-6">
                  <div className="timeline-item d-flex justify-content-center">
                    <div className="timeline-icon bg-success rounded-circle d-flex justify-content-center align-items-center">
                      <span className="fs-6 fs-lg-5 fs-xl-4 text-white">2</span>
                    </div>
                  </div>
                </div>
                <div className="py-1 py-lg-0 px-lg-5 w-75 w-sm-50 w-lg-100 timeline-content">
                  <h6 className="fs-8 fw-bold text-lg-center lh-lg mb-2">
                    Créer ou rejoindre une réunion
                  </h6>
                </div>
              </div>
              <div className="col-12 col-lg-4 d-flex flex-row flex-lg-column justify-content-center gap-2 gap-sm-x1 gap-md-4 gap-lg-0">
                <div className="timeline-step-3 position-relative z-1 overflow-hidden w-25 w-lg-100 mb-4 mb-lg-5 mb-xl-6">
                  <div className="timeline-item d-flex justify-content-center">
                    <div className="timeline-icon bg-info rounded-circle d-flex justify-content-center align-items-center">
                      <span className="fs-6 fs-lg-5 fs-xl-4 text-white">3</span>
                    </div>
                  </div>
                </div>
                <div className="py-1 py-lg-0 px-lg-5 w-75 w-sm-50 w-lg-100 timeline-content">
                  <h6 className="fs-8 fw-bold text-lg-center lh-lg mb-2">
                    Contrôler vos préférences dans la réunion
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container-fluid  wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ marginTop: "-8%" }}
      >
        <div className="container py-3">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="mb-0">
              Les avantages de notre plateforme de visioconférence{" "}
            </h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item  rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <img src="images/video-quality.png" alt="video" />
                </div>
                <h4 className="mb-3">Qualité vidéo HD</h4>
                <p className="m-0">
                  Profitez de visioconférences d'une qualité vidéo haute
                  définition pour des interactions plus claires et plus
                  dynamiques.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item  rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <img src="images/reading-book.png" alt="facilité" />
                </div>
                <h4 className="mb-3">Facilité d'utilisation</h4>
                <p className="m-0">
                  Notre plateforme est intuitive et facile à utiliser,
                  permettant à tous les utilisateurs de se connecter rapidement
                  et sans complications.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
              <div className="service-item  rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <img src="images/export-file.png" alt="fichier" />
                </div>
                <h4 className="mb-3">Partage de fichier</h4>
                <p className="m-0">
                  Partagez des documents, des présentations et d'autres fichiers
                  en temps réel pendant vos visioconférences pour une
                  collaboration efficace.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright_section">
        <div class="container">
          
          <p className="copyright_text">
            {" "}
            Copyright &#169; 2024 <a href="/">KAPITAL-VISIO.</a>Tous droits réservés.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Accueil;
